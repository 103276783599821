<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="userModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
                <CCol md="12">
                  <CDataTable
                    hover
                    border
                    fixed
                    :items="form.permissions"
                    :fields="fields"
                    :items-per-page="30"
                    pagination
                    size="sm"
                    :header="false"
                  >
                    <template #name="{item}"><td class="text_small">{{item.name}}</td></template>
                    <template #actions="{item}">
                      <td class="w50">
                        <CSwitch
                            v-model="
                            item.selected"
                            :checked.sync="item.selected"
                            class="mx-1"
                            color="info"
                            size="sm"
                            name="switch1"
                            @update:checked="selectProduct(item)"
                          />
                      </td>
                    </template>
                  </CDataTable>
                </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import roles from '../../services/roles';

export default {
  name: 'RolModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      userModal: false,
      title: "Nuevo Rol",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Permisos del Acceso'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
      let response = await roles.get(); 

      if(response.type == "success"){
        this.roles = response.data;
      }

      this.rolesOptions = await this.$parseSelectOptionsOrdered(this.roles, "slug", "name");
  },
  methods: {
    storeModal () {
      this.userModal = true;
      this.color = "info";
      this.title = 'Nuevo Rol';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          permissions: this.permissions
      }
    },
    updateModal (item) {
      this.userModal = true;
      this.color = "warning";
      this.title = 'Editar Rol y Permisos';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          permissions: []
      };

      let permissions = item.permissions;

      this.permissions.forEach(permission => {
          let perm = this.$findElementByCode(this.permissions, permission);
          
          let tmp = {
            code: permission.code,
            name: permission.name,
            selected: false
          }

          this.form.permissions.push(tmp);
      });

      for (const permission in permissions) {
          let perm = this.$findElementByCode(this.form.permissions, permission);

          perm.selected = true;
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.userModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>